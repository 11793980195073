<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :busy="storeIsBusy"
      id="employee-page-table"
      ref="employee-page-table"
      table-class="table-head-custom table-vertical-center"
      responsive
      hover
      show-empty
      @row-clicked="showEmployeeDetails"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(full_name)="data">
        <CommonEmployeeSummary
          :item="convertToSummary(data)"
        ></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(group)="data">
        {{ data.item.group }}
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEmployeeDetails(data.item)"
            v-b-tooltip.hover
            :title="$t('TEACHER.TOOLTIP.EDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger mr-3"
            v-on:click="deleteTeacher(data)"
            v-b-tooltip.hover
            :title="$t('TEACHER.TOOLTIP.DELETE', { teacher: data.item.name })"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          :text-top="$t('EMPLOYEETABLE.Empty.Title')"
          :text-bottom="$t('EMPLOYEETABLE.Empty.Subtitle')"
        >
        </AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>

    <TeacherPageDetailsModal
      :selected-employee="selectedEmployee"
      @detailsUpdated="getTeachers"
      :title="selectedEmployee.name"
    >
    </TeacherPageDetailsModal>
    <CompanyAdminAssignmentModal
      :employee="selectedEmployee"
    ></CompanyAdminAssignmentModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import CompanyAdminAssignmentModal from "@/modules/company/components/employees/admin/CompanyAdminAssignmentModal";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import TeacherPageDetailsModal from "@/modules/school/components/teacher/list/TeacherPageDetailsModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "TeacherTable",
  props: ["reload"],
  components: {
    AppEmptyList,
    TeacherPageDetailsModal,
    CommonEmployeeSummary,
    CompanyAdminAssignmentModal,
    AppLoader,
  },
  mounted() {
    this.getTeachers();
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "full_name", label: this.$t("TEACHER.HEADER"), sortable: true },
        { key: "group", label: "Group", sortable: true },
        { key: "actions", label: this.$t("EDUTABLE.ACTIONS") },
      ],
      selectedEmployee: {},
      isBusy: false,
    };
  },
  methods: {
    /**
     * Fetch teachers.
     *
     * @returns {Promise<boolean>}
     */
    getTeachers() {
      this.isBusy = true;

      this.resetFilters();

      return this.$store
        .dispatch("fetchTeacherList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          this.$bvModal
            .msgBoxOk("An error occurred.", {
              title: "Cannot retrieve teachers",
              size: "sm",
              buttonSize: "sm",
              centered: true,
              okVariant: "light",
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch(() => {
              // An error occurred
            });
        })
        .finally(() => (this.isBusy = false));
    },

    resetFilters() {
      this.$store.dispatch("changeTeacherListNameFilter", {
        name: null,
      });
    },

    /**
     * Show emp details modal.
     *
     * @param item
     */
    showEmployeeDetails(item) {
      console.log("Selected teacher ", item);

      this.selectedEmployee = {
        name: `${item.first_name} ${item.last_name}`,
        firstName: item.first_name,
        lastName: item.last_name,
        code: item.code,
        email: item.email,
        id: item.id,
        image_url: item.image_url,
      };

      this.$bvModal.show("common-employee-details-modal");
      this.$store.dispatch("assignEmployeeId", item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },

    /**
     * Delete teacher.
     *
     * @param row
     */
    deleteTeacher(row) {
      console.log("Selected Teacher ", row.item);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.item.first_name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteTeacher", row.item.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.getTeachers();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    refresh() {
      console.log("Reload Table!");
      this.$refs["employee-page-table"].refresh();
    },

    /**
     * Change page.
     *
     * @param page
     */
    changePage(page) {
      console.log("Changing to page:", page);
      this.$store.commit("setTeacherListPage", page);
      this.getTeachers();
    },

    /**
     * Assign one teacher as admin.
     *
     * @deprecated
     * @param data
     */
    assignAsAdmin(data) {
      console.log("Assigning ", data.item.id);
      this.selectedEmployee = {
        name: data.item.first_name,
        id: data.item.id,
        image_url: data.item.image_url,
      };
      this.$bvModal.show("employee-admin-assignment-modal");
    },

    /**
     * Convert Row (JSON) for CommonEmployeeSummary
     *
     * @param row
     * @returns {{code, name: string, email}}
     */
    convertToSummary(row) {
      console.log(row);
      const item = row.item;
      return {
        name: item.first_name + " " + item.last_name,
        code: item.code,
        email: item.email,
        image_url: item.image_url,
      };
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.getTeachers();
    },
  },

  computed: {
    ...mapGetters(["getSuperAdminPermission"]),
    ...mapGetters({
      items: "getTeacherList",
      pagination: "getTeacherListPagination",
      currentPage: "getTeacherListPage",
      storeIsBusy: "getTeacherListBusy",
    }),
  },
};
</script>

<style scoped></style>
}
