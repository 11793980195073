<template>
  <div>
    <TeacherTable :reload="reload"></TeacherTable>


  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TeacherTable from "@/modules/school/components/teacher/list/TeacherPageTable";

export default {
  name: "TeacherListPage",
  components: {
    TeacherTable
  },
  data() {
    return {
      reload: false,
    };
  },
  mounted() {
    console.log("Employee List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/school/teacher/list" },
    ]);
  },
  methods: {

    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
